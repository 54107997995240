import {addSeconds} from 'date-fns';
import {format} from 'date-fns/format';

export interface Options {
  durationSeconds: number;
  // If it's number, it needs to be in seconds UTC, if it's string, it
  // needs to be able to be parsed by Date constructor
  startTime: number | string;
}

export default function formatHourRange(options: Options): string {
  const {durationSeconds, startTime} = options;
  const startTimeDate = new Date(
    typeof startTime === 'string' ? startTime : startTime * 1000,
  );
  const endTimeDate = addSeconds(startTimeDate, durationSeconds);

  const startHour = format(startTimeDate, 'p');
  const endHour = format(endTimeDate, 'p');

  return `${startHour} - ${endHour}`;
}
