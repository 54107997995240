import {EventCardTimeInfo_GroupSessionFragment} from './__generated__/Event.graphql';
import formatHourRange from '../../time/formatHourRange';
import formatMonthDateDay from '../../time/formatMonthDateDay';
import useAppTheme from '../design_system/hooks/useAppTheme';
import Calendar from '../design_system/icon/Calendar';
import {ContentColor} from '../design_system/theme/useContentPalette';
import Typography, {Size} from '../design_system/typography/Typography';
import Flex from '../layout/flex/Flex';

import {css} from '@emotion/react';
import React from 'react';

export interface Props {
  readonly data: EventCardTimeInfo_GroupSessionFragment;
  readonly color?: ContentColor;
}

export default function EventCardTimeInfo(props: Props): JSX.Element {
  const {color = ContentColor.PRIMARY, data} = props;
  const {spacing} = useAppTheme();

  const date = formatMonthDateDay(data.startTimeUTCSeconds);
  const hourRange = formatHourRange({
    durationSeconds: data.durationSeconds,
    startTime: data.startTimeUTCSeconds,
  });
  const eventTime = `${date} ${hourRange}`;

  return (
    <Flex
      sx={css({
        alignItems: 'flex-start',
      })}
    >
      <Flex
        css={css({
          height: 32,
        })}
      >
        <Calendar
          color={color}
          size={20}
        />
      </Flex>
      <Typography
        color={color}
        css={css({marginLeft: spacing.x8})}
        size={Size.M}
      >
        {eventTime}
      </Typography>
    </Flex>
  );
}
