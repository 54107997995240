import type {ExtraInfo} from './types';

const DATA: ExtraInfo = {
  details: [
    {
      type: 'description',
      heading: 'イベント概要',
      paragraphs: [
        {
          content:
            'allizは、経験豊富なメンターとのカジュアルな会話を通じてキャリアの発展を促進するコミュニティ主導のデジタルスペースです。キャリアデザインやIT業界でのキャリアアップを目指す方々に学びと成長の機会を提供し、知識の共有とメンターシップを促進します。',
        },
        {
          content:
            '多くのメンターは無償で自分の時間や専門知識を提供し、他の人たちのキャリア形成をサポートしています。なぜこのような仕組みが生まれたのでしょうか？メンターたちを突き動かすものは何なのか？考えてみたら不思議ですよね。',
        },
        {
          content:
            '今回の「メンターシップの価値を探る」と題したイベントでは、慶應義塾大学の荒谷 大輔教授をお招きし、贈与経済1.0および2.0の概念と、allizのようなコミュニティベースのメンターサービスとの共通点についてお話しいただきます。また、Indeedを始めとするIT企業からメンターの方々にもご参加いただき、IT業界におけるキャリアパス、スキル形成や経験について少人数でのグループディスカッションを行います。当日はオープンスペーステクノロジー（OST）の手法に沿った形で進めますので、インタラクティブかつ主体的な対話を楽しんでください。参加者それぞれが関心のある重要なテーマについて掘り下げ、深い洞察を得ることを目指します。',
        },
        {
          content:
            'このイベントは大学生や社会人の方を対象しており、専門分野や経験のレベルは問いません。キャリアガイダンスやメンターシップを求める学生、またメンターシップやコミュニティの発展に関心のある社会人の方におすすめです。冬休みや年末年始の休暇に入る前に皆で学び、自分を見つめ直して一年を締めくくりませんか？',
        },
      ],
    },
    {
      type: 'list',
      heading: 'こんな方におすすめ',
      items: [
        'キャリアデザインに関する目標とアドバイスを求めている方',
        'IT業界でのキャリアを目指している方',
        'メンターシップに興味がある方',
        'コミュニティサービスの構築に関心のある方',
        'ネットワーキングの機会を求めている方',
      ],
    },
    {
      type: 'description',
      heading: '登壇者・トピックの紹介',
      paragraphs: [
        {
          heading: '慶應義塾大学文学部 荒谷 大輔教授',
          content:
            '哲学者。現代社会の構造分析を経て、その改善案の社会実装を目指して活動する。主な著作に『資本主義に出口はあるか』（講談社新書）『贈与経済2.0：お金を稼がなくても生きていける世界で暮らす』（翔泳社）など。',
        },
        {
          heading: 'トピック：贈与経済2.0',
          content:
            '私たちは、他者から感謝を得ることが、しっかりとした社会的な価値を持つようなプラットフォーム（＝贈与経済2.0）を作ろうとしています。誰かのために何かをすることが、直接的な見返りを求めない無償のものであると同時に、得られた感謝が贈与経済上のインセンティブとなるような仕組みです。お金とは違う基準で人々が繋がっていくことの価値が評価されるような社会の実現を目指して頑張っています。',
        },
        {
          content:
            '贈与経済2.0についての詳細は https://heart-land.io/ をご覧ください。',
        },
        {
          heading: 'allizのメンターたち',
          content:
            '当日は、Indeedを始めとするIT企業から10名以上のメンターが参加予定です。エンジニア、プロダクトマネジメント、UI/UXデザイナー、マーケティング、営業担当などさまざまなバックグラウンドを持つメンターと少人数のグループディスカッションを体験しましょう。メンターの詳細はイベントの約1週間前に公開されます。最新情報はLinkedIn（@alliz）をフォローしてください。',
        },
        {
          content:
            '以下は、小グループディスカッションを主導するメンターのリストです。',
        },
        {
          content:
            '1. Indeed Technologies Japan株式会社 スタッフソフトウェアエンジニア 周 暁偉 氏',
        },
        {
          content:
            '2. 楽天グループ株式会社 プロダクトマネージャー 中野 陽子 氏',
        },
        {
          content: '3. PayPay株式会社 プロダクトマネージャー ヴ アン 氏',
        },
        {
          content: '4. Slalom株式会社 エクスペリエンスデザイナー 小檜山 諒 氏',
        },
        {
          content:
            '5. 株式会社アップネクスト CEO 鈴木 海晃 氏 (青山学院大学 学生起業家)',
        },
        {
          content: '6. Adobe株式会社 Go-To-Marketマネージャー 大木 慧 氏',
        },
        {
          content:
            '7. Kraken-Octopus Energy シニアデータエンジニア ナンド アカシュ  氏',
        },
      ],
    },
    {
      type: 'schedule',
      heading: 'タイムテーブル',
      items: [
        {
          timeUTC: 1733994000,
          description: '受付開始　(受付は18:30まで)',
        },
        {
          timeUTC: 1733995800,
          description: 'オープニング',
        },
        {
          timeUTC: 1733997600,
          description: 'ゲストトーク: 贈与経済2.0',
          info: ['慶應義塾大学文学部 荒谷 大輔教授'],
        },
        {
          timeUTC: 1733999400,
          description: 'Q&A',
        },
        {
          timeUTC: 1734000000,
          description: '休憩',
        },
        {
          timeUTC: 1734000600,
          description: 'キャリアに関するグループディスカッション',
          info: ['Tech Career Mixer・IT業界で働く'],
        },
        {
          timeUTC: 1734002400,
          description: 'ネットワーキング',
        },
        {
          timeUTC: 1734004200,
          description: 'クロージング',
        },
        {
          timeUTC: 1734004800,
          description: '閉会',
        },
      ],
    },
    {
      type: 'description',
      heading: '注意事項',
      paragraphs: [
        {
          content:
            '申し込み：本イベントは参加無料です。定員は100名（学生60名、社会人40名）で、その内10名がallizのメンターです。申し込み完了次第、カレンダーの招待メールをお送りします。申し込み締切は2024年12月11日（水）です。',
        },
        {
          content:
            '言語：イベントは日本語で行われますが、必要に応じて英語を使用する場合があります。ネットワーキングの時間は特に言語の指定はありません。',
        },
        {
          content:
            '軽食・お飲み物：会場には軽食とお飲み物をご用意しています。アレルギーのある方はご自身にてご配慮いただきますようお願いします。 ',
        },
        {
          content:
            '写真撮影：イベント中に写真撮影を行います。撮影された写真や動画は、allizのウェブサイトやソーシャルメディアにて宣伝目的で使用される場合があります。',
        },
        {
          content: 'スケジュール：スケジュールは当日変更になる場合があります。',
        },
        {
          content:
            'お問い合わせ：イベントに関するお問い合わせは、alliz（event@alliz.jp）までメールでお気軽にご連絡ください。皆様のご参加を心よりお待ちしております。',
        },
      ],
    },
  ],
  tags: [],
  thumbnailURL:
    '/static/images/kMASOkQt47/weBBN9bXTc2K4DTNKrx80ZYojKiJA0DV.png',
};

export default DATA;
