import type {ExtraInfo} from './types';

const DATA: ExtraInfo = {
  tags: [],
  thumbnailURL: '/static/images/15e3b947917f61b0f2a76e20ab4ac6c9.jpeg',
  details: [
    {
      type: 'description',
      heading: 'イベント概要',
      paragraphs: [
        {
          content:
            'allizは、経験豊富なメンターとのカジュアルな会話を通じてキャリアの発展を促進するコミュニティ主導のデジタルスペースです。キャリアデザインやIT業界でのキャリアアップを目指す方々に学びと成長の機会を提供し、知識の共有とメンターシップを促進します。',
        },
        {
          content:
            'Adobe Expressチームとのパートナーシップで初のキャリアイベントを4月13日に開催します。当日は、Adobe Expressでキャリアに役立つデザインワークショップや、グローバルIT企業に務める経験者のパネルディスカッション、ネットワーキングの時間を予定しています。ぜひご参加ください。',
        },
      ],
    },

    {
      type: 'list',
      heading: 'こんな方におすすめ',
      items: [
        'allizに興味がある方',
        'キャリアデザインに関する目標とアドバイスを求めている方',
        '就職活動の準備をしている方',
        'デザインに関する目標を持っている方',
        'Adobe Expressに興味がある方',
        'ネットワーキングの機会を求めている方',
      ],
    },

    {
      type: 'schedule',
      heading: 'タイムテーブル',
      items: [
        {
          timeUTC: 1712983500,
          description: '受付開始　(受付:14:20まで)',
        },
        {
          timeUTC: 1712985300,
          description: 'オープニング',
        },
        {
          timeUTC: 1712986200,
          description: 'デザインワークショップ',
          info: ['Adobe Expressで名刺を作成してみましょう'],
        },
        {
          timeUTC: 1712988000,
          description: '休憩',
        },
        {
          timeUTC: 1712988900,
          description: 'キャリアに関するパネルディスカッション',
          info: ['グローバルIT企業におけるキャリアについて'],
        },
        {
          timeUTC: 1712990700,
          description:
            'ネットワーキング　( ソフトドリンク・スナックが提供されます。)',
        },
        {
          timeUTC: 1712994300,
          description: 'クロージング',
        },
        {
          timeUTC: 1712995200,
          description: '閉会',
        },
      ],
    },

    {
      type: 'list',
      heading: '申し込みについて',
      items: [
        '募集人数: 30名',
        '参加費用: 無料',
        '申し込み締切: 2024年4月10日',
      ],
    },

    {
      type: 'list',
      heading: '注意事項',
      items: [
        '席数に限りがありますので、参加者が定員の30名に達した場合、ウェイティングリストへ追加させていただきます。',
        'ワークショップ関連: 当日はパソコンのご持参を推奨します。また、イベント前にAdobe Expressのユーザー登録が必要です。',
        '写真撮影: イベント中に写真撮影をしますが、個人の承認なしに使用することはありません。',
        '飲み物とスナック: 会場には飲み物とスナックが用意されますが、アレルギーのある方はご自身にてご配慮いただきますようお願いします。',
        'イベントに関しての連絡はAdobeにしないようお願いします。イベントに関しての質問は、下記連絡先までお願いします。',
        '連絡先: 主催者allizのメールアドレスは event@alliz.jp です。',
      ],
    },
  ],
};

export default DATA;
