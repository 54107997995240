import type {ExtraInfo} from './types';

const DATA: ExtraInfo = {
  tags: [],
  details: [
    {
      type: 'description',
      heading: 'Event Overview',
      paragraphs: [
        {
          content:
            'alliz is a career mentorship community run by mentors within the community, focused on career design and development in Japan. “alliz eX” spotlights individual career choices, inviting guest speakers from leading IT companies with diverse, international backgrounds to share their insights.',
        },
        {
          content:
            'The upcoming “Founders Panel” centers around the theme of "Founder as a Career Choice – The Path of Starting a Company." We have invited experienced founders from growing tech companies to share their entrepreneurial stories and key strategies for business growth. This event is ideal for anyone looking to start a company, join a startup, or who is considering entrepreneurship in the near future. Don’t miss this opportunity to learn from founders and expand your network! ',
        },
        {
          content:
            'University students and professions of any field and experience level are welcome. Due to limited seating, this event is exclusive to alliz community members.  It’s first-come, first-served. So if you’re not a member yet, register and set up your member profile.',
        },
        {
          content:
            'Language: Event will be conducted primarily in English, but there will be Q&A sessions and networking time, allowing interaction in both English and Japanese. ',
        },
      ],
    },
    {
      type: 'list',
      heading: 'Who is this for',
      items: [
        'Aspiring founders or those interested in joining early-stage startups.',
        'Early-stage entrepreneurs seeking insights, networking, or business partners.',
        'Students and Professions looking to network in the tech industry.',
        'Students and Professions interested in mentorship opportunities.',
        'Students and Professions seeking career design advice and guidance.',
      ],
    },
    {
      type: 'description',
      heading: 'Guest Introduction',
      paragraphs: [
        {
          heading: 'Guest Panelist #1: Eiko NAKAZAWA, Co-Founder & CEO, ikura',
          content:
            'Eiko is an entrepreneur specializing in global branding, digital marketing, and new business development. Her career began at Sony, where she worked in international expansion and sales before earning an MS in Management from Stanford. She then led branding efforts at Sony Corporation of America. In 2017, Eiko founded Dearest in the U.S., a company supporting working parents. Later, she co-founded ikura, a platform that showcases unique Japanese experiences to the world, further bridging cultural gaps through innovative business solutions.',
        },
        {
          heading: 'Guest Panelist #2: Yan FAN, Co-Founder, Code Chrysalis',
          content:
            'Yan Fan co-founded Code Chrysalis in 2017, a Tokyo-based company that trains software engineers, aiming to make Japan a global software leader. Prior to that, she co-founded and served as CTO of a coding bootcamp in Jordan and also worked as a software engineer at a Silicon Valley startup. Yan graduated from Dartmouth College with degrees in Economics and Arabic and briefly worked in agri-commodities trading in Singapore before changing her career to tech. She was born in China and grew up in Seattle.',
        },
        {
          heading:
            'Guest Panelist #3: Charles GREEN, Founder & CEO, Secure SaaS & Marketplace Trends',
          content:
            'Charles is a serial entrepreneur with more failures than successes, founder of Secure SaaS specializing in cybersecurity for SaaS and FinTech companies, and the recent founder of Marketplace Trends, focusing on data analytics to drive SaaS company growth.',
        },
      ],
    },
    {
      type: 'schedule',
      heading: 'Timetable',
      items: [
        {
          timeUTC: 1729587600,
          description: 'Doors open (Reception close by 18:30)',
        },
        {
          timeUTC: 1729589400,
          description: 'Opening',
        },
        {
          timeUTC: 1729589700,
          description:
            'Lightning Talk: "From Oversupply to Demand Tension: The Key to Success"',
          info: [
            'Charles GREEN, Founder & CEO, Secure SaaS & Marketplace Trends',
          ],
        },
        {
          timeUTC: 1729590000,
          description:
            'Panel Discussion: "Founder as a Career Choice - The Path of Starting a Company"',
          info: [
            'Eiko NAKAZAWA, Co-Founder & CEO, ikura',
            'Yan FAN, Co-Founder, Code Chrysalis',
            'Charles GREEN, Founder & CEO, Secure SaaS & Marketplace Trends',
          ],
        },
        {
          timeUTC: 1729591800,
          description: 'Q&A',
        },
        {
          timeUTC: 1729592700,
          description: 'Networking',
        },
        {
          timeUTC: 1729595700,
          description: 'Closing',
        },
        {
          timeUTC: 1729596600,
          description: 'Event close',
        },
      ],
    },
    {
      type: 'list',
      heading: 'About Registration',
      items: [
        'Total participants: 50',
        'Participation fee: Free',
        'Registration deadline is October 21, 2024 (Monday)',
        'Due to limited seating, please sign up only if you can attend.',
        'If we reach capacity, you can contact alliz to join the waiting list.',
        'If you need to cancel, you can cancel the event on this page.',
      ],
    },
    {
      type: 'list',
      heading: 'Important Information',
      items: [
        'Event schedule may change on the event day.',
        'Refreshments: Drinks and light-food will be provided. Please manage any allergies accordingly. You are welcome to bring your own food and drinks.',
        "Photography: Event photos and videos may be used for promotional purposes on alliz's website, social media, and event announcements.",
        'Please direct any event-related inquiries to alliz at event@alliz.jp.',
      ],
    },
  ],
  thumbnailURL:
    '/static/images/kMASOkQt47/zMSHZMBYKqG0raoGrUrdVWGkntxW5N9Z.png',
};

export default DATA;
