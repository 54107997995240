import type {ExtraInfo} from './types';

const DATA: ExtraInfo = {
  details: [
    {
      type: 'description',
      heading: 'Event Overview',
      paragraphs: [
        {
          content:
            'alliz is a career mentorship community run by its members, focused on career design and development in Japan. At alliz, we emphasize fostering mentorship and creating meaningful connections through community-driven experiences. Our unique approach connects students, early-career professionals, and mentors from various tech-related industries to share experiences, insights, and guidance, promoting mutual growth and learning.',
        },
        {
          content:
            'With a mission to expand mentorship and career-building experiences beyond Tokyo, alliz is hosting its first-ever event, “with alliz in Sapporo: Tech Career Panel,” at Hokkaido University Open Innovation Hub ENREISO in Sapporo. By connecting local students and talents with inspiring tech professionals, alliz aims to foster growth in tech communities across Japan. Our panelists are currently working remotely in Sapporo for large tech companies, each with international working experience. They’ll share insights into building successful tech careers, offering perspectives on the benefits of working in Hokkaido and how to thrive in an fast-evolving industry. This event offers a unique opportunity to gain firsthand knowledge about tech trends and  career paths, and expand your tech network in and beyond Sapporo.',
        },
        {
          content:
            'This event is open to anyone in Hokkaido who is curious about working in tech, interested in career development, eager to explore mentorship opportunities or interested in building professional communities. University students and professionals of any field and experience level are welcome. Join us and take the next step in your tech career journey! We look forward to seeing you in Sapporo!',
        },
      ],
    },
    {
      type: 'list',
      heading: 'Who is this for',
      items: [
        'Students and professionals curious about working in tech industry',
        'Students and professionals interested in exploring careers in tech industry',
        'Students and professionals seeking guidance and mentorship for career design and growth',
        'Students and professionals looking for networking opportunities',
        'Professionals eager to share their knowledge and experience, and connect with local talents',
      ],
    },
    {
      type: 'description',
      heading: 'Guest Introduction',
      paragraphs: [
        {
          heading: 'Panelist : Jimmy Lin, Senior Data Scientist, PayPay',
          content:
            'Jimmy is a senior data scientist at PayPay, building up fundamental analytics and improving user experience and company priorities with data. Prior to moving to Japan last year, he worked at Meta and had seven years of experience from consulting to SaaS to consumer tech in Silicon Valley. In life, he spends much time beefing up his poor Japanese, dreaming about opening a cafe with his fiance in Sapporo, and traveling around Japan to meet new and old friends.',
        },
        {
          heading:
            'Panelist: Yuki Motomura, Customer Success Manager, LinkedIn Japan',
          content:
            'Yuki currently serves as a Customer Success Manager at LinkedIn Japan.  Previously, he held roles as a Project Manager at Meta (Facebook) in Singapore, an Insights Analyst at LinkedIn, and a Consultant at Deloitte Tohmatsu Consulting in Japan. Apart from work, Yuki is deeply passionate about advocating for persons with disabilities (PWDs) and founded a nonprofit organization in Sapporo dedicated to supporting children with disabilities.',
        },
        {
          heading: 'Moderator: Congqin Chen, Founder, alliz',
          content:
            'Congqin is the founder of alliz, a career mentorship community launched in March 2024. Congqin started career as an Engineer at Rakuten,  then worked in multiple leading US tech and consulting firms in Japan and built expertises in areas of data analytics and business intelligence. Currently, she holds the Data & Analytics Consultant role at Slalom. Interestingly, she and our two panelists all worked at Meta, though they never met. Curious about their connection and how they came together for this panel? Join to hear their story and learn more about alliz.',
        },
      ],
    },
    {
      type: 'schedule',
      heading: 'Timetable',
      items: [
        {
          timeUTC: 1739250000,
          description: 'Doors Open',
        },
        {
          timeUTC: 1739251800,
          description: 'Opening and Introduction',
        },
        {
          timeUTC: 1739252400,
          description: 'Panel Discussion: Tech Career in Sapporo',
        },
        {
          timeUTC: 1739254200,
          description: 'Q&A',
        },
        {
          timeUTC: 1739255100,
          description: 'Networking',
        },
        {
          timeUTC: 1739258100,
          description: 'Closing',
        },
        {
          timeUTC: 1739259000,
          description: 'Event Close',
        },
      ],
    },
    {
      type: 'description',
      heading: 'Important Information',
      paragraphs: [
        {
          content:
            'Application: The event is free to attend and will accommodate up to 50 participants. Once your registration is confirmed, you will receive a calendar invitation. Please sign up by Sunday, February 9, 2025.',
        },
        {
          content:
            'Language: The event will primarily be conducted in English. During networking time, conversations in both English and Japanese are welcome.',
        },
        {
          content:
            'Refreshments: No food or drinks will be provided, but you are welcome to bring your own snacks and soft drinks. ',
        },
        {
          content:
            'Photography: Photos and videos may be taken during the event and could be used for promotional purposes on alliz’s website and social media. By signing up for this event, you give us permission to use these photos and videos. If you do not want to be photographed but still wish to attend, please contact alliz.',
        },
        {
          content:
            'Schedule: The event schedule is subject to change on the day.',
        },
        {
          content:
            'Contact: For any event-related inquiries, please contact alliz via email at event@alliz.jp.',
        },
      ],
    },
  ],
  tags: [],
  thumbnailURL:
    '/static/images/kMASOkQt47/1CDR5nmNdsdoLh1waJLLeccyCcVlPK9N.png',
};

export default DATA;
