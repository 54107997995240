import {GetEventTitleWithModifier_GroupSessionFragment} from './__generated__/Event.graphql';
import getEventStatus from './getEventStatus';
import {
  eventCompleted,
  eventInProgress,
} from '../../../__generated__/intl/strings/components/eventCard';

export default function getEventTitleWithModifier(
  groupSession: GetEventTitleWithModifier_GroupSessionFragment,
): string {
  const status = getEventStatus(groupSession);

  switch (status) {
    case 'completed':
      return eventCompleted() + ' ' + groupSession.title;
    case 'in-progress':
      return eventInProgress() + ' ' + groupSession.title;
    case 'pending':
      return groupSession.title;
  }
}
