import {EventCardLocationInfo_VenueFragment} from './__generated__/Event.graphql';
import {eventOnlineVenue} from '../../../__generated__/intl/strings/components/eventCard';
import useAppTheme from '../design_system/hooks/useAppTheme';
import Globe from '../design_system/icon/Globe';
import MapPin from '../design_system/icon/MapPin';
import {ContentColor} from '../design_system/theme/useContentPalette';
import Typography, {Size} from '../design_system/typography/Typography';
import Flex from '../layout/flex/Flex';

import {css} from '@emotion/react';
import React from 'react';

export interface Props {
  readonly data: Maybe<EventCardLocationInfo_VenueFragment>;
  readonly color?: ContentColor;
}

export default function EventCardLocationInfo(props: Props): JSX.Element {
  const {color = ContentColor.PRIMARY, data} = props;
  const {spacing} = useAppTheme();

  if (data == null) {
    return (
      <Flex
        sx={css({
          alignItems: 'flex-start',
        })}
      >
        <Flex
          sx={css({
            height: 32,
          })}
        >
          <Globe
            color={color}
            size={20}
          />
        </Flex>
        <Typography
          color={color}
          css={css({marginLeft: spacing.x8})}
          size={Size.M}
        >
          {eventOnlineVenue()}
        </Typography>
      </Flex>
    );
  }

  return (
    <Flex sx={css({alignItems: 'flex-start'})}>
      <Flex sx={css({height: 32})}>
        <MapPin
          color={color}
          size={20}
        />
      </Flex>
      <Typography
        color={color}
        css={css({marginLeft: spacing.x8})}
        size={Size.M}
      >
        {data.station}
      </Typography>
    </Flex>
  );
}
