import intl from '../../intl';

export function eventCompleted(): string {
  return intl.get('components.event-card.completed').d('[Completed]');
}

export function eventInProgress(): string {
  return intl.get('components.event-card.in-progress').d('[In progress]');
}

export function eventOnlineVenue(): string {
  return intl.get('components.event-card.online-venue').d('Online');
}
